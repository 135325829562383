// src/shared/components/Header/Navigation.js
import React from "react";
import { Flex } from "@chakra-ui/react";
import NavLink from "./NavLink";
import { navLinks } from "./navData";
import { headerStyles } from "./Header.styles";

const Navigation = () => (
  <Flex sx={headerStyles.navigation}>
    {navLinks.map(link => (
      <NavLink key={link.to} {...link} />
    ))}
  </Flex>
);

export default React.memo(Navigation);