// src/shared/components/Footer/Footer.js
import React from 'react';
import { Box, Text } from '@chakra-ui/react';

const Footer = () => (
  <Box as="footer" width="full" py={4} textAlign="center">
    <Text>&copy; {new Date().getFullYear()} Babi Bookstore. Todos os direitos reservados.</Text>
  </Box>
);

export default Footer;