// src/shared/components/Header/NavLink.js
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link, Flex, Box } from "@chakra-ui/react";
import { headerStyles } from "./Header.styles";

const NavLink = ({ icon: Icon, label, to }) => (
  <Link as={RouterLink} to={to} sx={headerStyles.navLink}>
    <Flex align="center">
      {Icon && <Box as={Icon} sx={headerStyles.navLinkIcon} />}
      <Box sx={headerStyles.navLinkText}>{label}</Box>
    </Flex>
  </Link>
);

export default React.memo(NavLink);