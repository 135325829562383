// src/shared/components/Header/SearchBar/SearchResultItem.js
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Text, Flex } from '@chakra-ui/react';
import BookImage from '../../../../common/BookImage';
import { searchBarStyles } from './SearchBar.styles';

const SearchResultItem = ({ book, onClose }) => {
  return (
    <Box as={RouterLink} to={`/book/${book.isbn}`} onClick={onClose} sx={searchBarStyles.resultItem}>
      <Flex alignItems="center">
        <BookImage
          imageLinks={book.imageLinks}
          alt={book.title}
          height="50px"
          width="50px"
          objectFit="cover"
          size="S"
        />
        <Box sx={searchBarStyles.resultContent}>
          <Text sx={searchBarStyles.resultTitle}>{book.title}</Text>
          <Text sx={searchBarStyles.resultAuthor}>{book.authors?.join(', ') || 'Unknown Author'}</Text>
          <Text sx={searchBarStyles.resultIsbn}>ISBN: {book.isbn}</Text>
          <Text sx={searchBarStyles.resultPrice}>
            {book.price ? `${Number(book.price).toFixed(2)}€` : 'Price not available'}
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};

export default React.memo(SearchResultItem);