// components/Loading.js
import React from 'react';
import { Spinner, Center } from '@chakra-ui/react';

const Loading = () => (
  <Center height="100vh">
    <Spinner size="xl" />
  </Center>
);

export default Loading;