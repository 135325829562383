// src/shared/components/Header/MobileMenu.js
import React from "react";
import { Box, VStack } from "@chakra-ui/react";
import { navLinks } from "./navData";
import NavLink from "./NavLink";
import { headerStyles } from "./Header.styles";

const MobileMenu = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <Box sx={headerStyles.mobileMenu}>
      <VStack align="stretch" spacing={0}>
        {navLinks.map(link => (
          <Box key={link.to} sx={headerStyles.mobileMenuItem} onClick={onClose}>
            <NavLink {...link} />
          </Box>
        ))}
      </VStack>
    </Box>
  );
};

export default MobileMenu;