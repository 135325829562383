// src/AppRouter.js
import React, { Suspense, lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Loading from "./common/Loading";
import Layout from "./components/Layout";

// Lazy load components
const Home = lazy(() => import("./pages/Home"));
const BookDetails = lazy(() => import("./pages/BookDetails"));
const AdminPanel = lazy(() => import("./components/AdminPanel/AdminPanel"));
const NotFound = lazy(() => import("./pages/NotFound"));

function AppRouter() {
  return (
    <Suspense fallback={<Loading />}>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/book/:isbn" element={<BookDetails />} />
          <Route path="/sombra" element={<AdminPanel />} />
          <Route path="/404" element={<NotFound />} />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
      </Layout>
    </Suspense>
  );
}

export default AppRouter;