// src/common/BookImage.js
import React from 'react';
import { Image, Box } from '@chakra-ui/react';

const BookImage = ({ imageLinks, alt, size = 'M', ...props }) => {
  const getImageUrl = (links, size) => {
    if (!links) return null;

    const sizeMap = {
      'S': 'small',
      'M': 'medium',
      'L': 'large'
    };

    const sizeKey = sizeMap[size] || 'medium';

    return links[sizeKey] || links.large || links.medium || links.small || links.thumbnail || null;
  };

  const imageSrc = getImageUrl(imageLinks, size);

  if (!imageSrc) {
    return (
      <Box
        height={props.height || 300}
        width={props.width || "100%"}
        bg="gray.200"
        display="flex"
        alignItems="center"
        justifyContent="center"
        color="gray.500"
      >
        Imagem não disponível
      </Box>
    );
  }

  return (
    <Image
      src={imageSrc}
      alt={alt}
      objectFit="cover"
      fallback={
        <Box
          height={props.height || 300}
          width={props.width || "100%"}
          bg="gray.200"
          display="flex"
          alignItems="center"
          justifyContent="center"
          color="gray.500"
        >
          Imagem não disponível
        </Box>
      }
      {...props}
    />
  );
};

export default React.memo(BookImage);