// src/components/Layout.js
import React from 'react';
import { Box } from '@chakra-ui/react';
import { Header } from '../shared/components/Header';
import Footer from '../shared/components/Footer/Footer';

const Layout = ({ children }) => (
  <Box minHeight="100vh" display="flex" flexDirection="column">
    <Header />
    <Box flex={1}>
      {children}
    </Box>
    <Footer />
  </Box>
);

export default Layout;