import axios from 'axios';

const baseURL =
  process.env.NODE_ENV === 'production'
    ? 'https://dev.bookstore.pedropinho.com/api'
    : 'http://localhost:5001/api'; // Match backend dev setup

const api = axios.create({
  baseURL,
  timeout: 10000,
});

export default api;

api.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error('API Error:', error.response || error.message);
    if (error.response) {
      console.error('Status:', error.response.status);
      console.error('Data:', error.response.data);
    }
    return Promise.reject(error);
  }
);


const createApiCall = (method, endpoint) => async (data = {}) => {
  const config = {
    method,
    url: endpoint,
    ...(method === 'get' ? { params: data } : { data }),
  };

  try {
    const response = await api(config);
    return response.data;
  } catch (error) {
    console.error(`API Error in ${method.toUpperCase()} ${endpoint}:`, error);
    throw error;
  }
};

export const fetchAllBooks = () => createApiCall('get', '/books/all')();

export const fetchBooks = () => createApiCall('get', '/books')();
export const searchBooks = query => createApiCall('get', '/books/search')({ query });
export const fetchBookByISBN = isbn => createApiCall('get', `/books/${isbn}`)();
export const createBook = data => createApiCall('post', '/books')(data);
export const updateBookByISBN = (isbn, data) => createApiCall('put', `/books/${isbn}`)(data);
export const deleteBookByISBN = isbn => createApiCall('delete', `/books/${isbn}`)();
export const sellBookByISBN = isbn => createApiCall('post', `/books/${isbn}/sell`)();
export const fetchAuthors = () => createApiCall('get', '/books/authors')();
export const fetchPublishers = () => createApiCall('get', '/books/publishers')();


console.log('API BaseURL: ', api.defaults.baseURL)