// src/shared/components/Header/SearchBar/SearchResults.js
import React from 'react';
import { VStack, Box, Text } from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';
import SearchResultItem from './SearchResultItem';
import { searchBarStyles } from './SearchBar.styles';

const SearchResults = React.memo(({ isOpen, results, bgColor, onClose }) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          transition={{ duration: 0.2 }}
        >
          <Box sx={searchBarStyles.results} bg={bgColor}>
            <VStack spacing={0} align="stretch">
              {results.length > 0 ? (
                results.map((book) => (
                  <SearchResultItem key={book.isbn} book={book} onClose={onClose} />
                ))
              ) : (
                <Text sx={searchBarStyles.noResults}>No results found</Text>
              )}
            </VStack>
          </Box>
        </motion.div>
      )}
    </AnimatePresence>
  );
});

export default SearchResults;