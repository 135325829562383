// src/shared/components/Header/SearchBar/SearchInput.js
import React from 'react';
import { Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { searchBarStyles } from './SearchBar.styles';

const SearchInput = React.forwardRef(({ value, onChange, onFocus }, ref) => {
  return (
    <InputGroup>
      <Input
        ref={ref}
        placeholder="Pesquisar livros..."
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onFocus={onFocus}
        sx={searchBarStyles.input}
      />
      <InputRightElement>
        <SearchIcon sx={searchBarStyles.icon} />
      </InputRightElement>
    </InputGroup>
  );
});

export default React.memo(SearchInput);