// src/shared/components/Header/SearchBar/SearchBar.js
import React, { useState, useRef } from 'react';
import { Box, useDisclosure, useColorModeValue } from '@chakra-ui/react';
import { useDebounce } from 'use-debounce';
import { searchBooks } from '../../../../utils/api';
import SearchInput from './SearchInput';
import SearchResults from './SearchResults';
import { searchBarStyles } from './SearchBar.styles';

const SearchBar = ({ width = "100%", maxWidth = "500px" }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [debouncedSearchTerm] = useDebounce(searchTerm, 300);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const inputRef = useRef();
  const bgColor = useColorModeValue('white', 'gray.700');

  React.useEffect(() => {
    if (debouncedSearchTerm) {
      searchBooks(debouncedSearchTerm)
        .then(response => {
          setSearchResults(Array.isArray(response) ? response : []);
          onOpen();
        })
        .catch(error => {
          console.error('Search error:', error);
          setSearchResults([]);
        });
    } else {
      setSearchResults([]);
      onClose();
    }
  }, [debouncedSearchTerm, onOpen, onClose]);

  return (
    <Box sx={{...searchBarStyles.container, width, maxWidth}}>
      <SearchInput
        ref={inputRef}
        value={searchTerm}
        onChange={setSearchTerm}
        onFocus={onOpen}
      />
      <SearchResults
        isOpen={isOpen}
        results={searchResults}
        bgColor={bgColor}
        onClose={onClose}
      />
    </Box>
  );
};

export default SearchBar;