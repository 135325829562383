// src/shared/components/Header/Header.styles.js

export const headerStyles = {
  container: {
    bg: "white",
    boxShadow: "md",
    position: "sticky",
    top: 0,
    zIndex: 1000,
    _dark: { bg: "gray.800" },
  },
  innerContainer: {
    maxWidth: "1200px",
    margin: "0 auto",
    padding: "1rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
  },
  logo: {
    fontSize: { base: "xl", md: "2xl" },
    fontWeight: "bold",
    color: "blue.600",
    _dark: { color: "blue.300" },
  },
  navigation: {
    display: { base: "none", md: "flex" },
  },
  navLink: {
    fontWeight: "medium",
    color: "gray.600",
    _hover: { color: "blue.600", textDecoration: "none" },
    _dark: { color: "gray.300", _hover: { color: "blue.300" } },
  },
  navLinkIcon: {
    marginRight: "0.5rem",
    fontSize: "1.2em",
  },
  navLinkText: {
    display: "inline-block",
  },
  searchBarWrapper: {
    width: { base: "100%", md: "400px" },
    mt: { base: 4, md: 0 },
  },
  mobileMenu: {
    display: { base: "block", md: "none" },
  },
  mobileMenuItem: {
    py: 2,
    px: 4,
    _hover: { bg: "gray.100" },
  },
};