// src/styles/theme.js
import { extendTheme } from "@chakra-ui/react";

// Global style overrides
const styles = {
  global: (props) => ({
    body: {
      bg: props.colorMode === "dark" ? "gray.800" : "white",
      color: props.colorMode === "dark" ? "white" : "gray.800",
    },
  }),
};

// Colors
const colors = {
  brand: {
    50: "#e6f6ff",
    100: "#b3e0ff",
    200: "#80caff",
    300: "#4db5ff",
    400: "#1a9fff",
    500: "#0080ff", // Primary brand color
    600: "#0066cc",
    700: "#004d99",
    800: "#003366",
    900: "#001a33",
  },
};

// Font Styles
const fonts = {
  heading: "'Poppins', sans-serif",
  body: "'Open Sans', sans-serif",
};

// Component Style Overrides
const components = {
  Button: {
    baseStyle: {
      fontWeight: "bold",
      borderRadius: "md",
    },
    variants: {
      solid: (props) => ({
        bg: props.colorMode === "dark" ? "brand.500" : "brand.600",
        color: "white",
        _hover: {
          bg: props.colorMode === "dark" ? "brand.600" : "brand.700",
        },
      }),
      // Add more button variants as needed
    },
  },
  // Add more component overrides as needed
};

// Other Customizations
const config = {
  initialColorMode: "light",
  useSystemColorMode: true,
};

// Breakpoints
const breakpoints = {
  sm: "30em",
  md: "48em",
  lg: "62em",
  xl: "80em",
  "2xl": "96em",
};

// Create and export the theme
const theme = extendTheme({
  styles,
  colors,
  fonts,
  components,
  config,
  breakpoints,
});

export default theme;