// src/shared/components/Header/Logo.js
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Heading } from "@chakra-ui/react";
import { headerStyles } from "./Header.styles";

const Logo = () => (
  <RouterLink to="/">
    <Heading sx={headerStyles.logo}>Bookstore</Heading>
  </RouterLink>
);

export default React.memo(Logo);