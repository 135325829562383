// src/shared/components/Header/Header.js
import React from "react";
import { Box, Flex, IconButton, useDisclosure } from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import Logo from "./Logo";
import Navigation from "./Navigation";
import SearchBar from './SearchBar/SearchBar';
import MobileMenu from "./MobileMenu";
import { headerStyles } from "./Header.styles";

const Header = () => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Box sx={headerStyles.container}>
      <Flex sx={headerStyles.innerContainer}>
        <Logo />
        <Navigation />
        <IconButton
          display={{ base: "flex", md: "none" }}
          onClick={onToggle}
          icon={<HamburgerIcon />}
          variant="ghost"
          aria-label="Toggle navigation"
        />
        <Box sx={headerStyles.searchBarWrapper}>
          <SearchBar />
        </Box>
      </Flex>
      <MobileMenu isOpen={isOpen} onClose={onToggle} />
    </Box>
  );
};

export default Header;