// src/shared/components/Header/SearchBar/SearchBar.styles.js

export const searchBarStyles = {
    container: {
      position: "relative",
      width: "100%",
      maxWidth: "600px",
    },
    input: {
      borderRadius: "full",
      bg: "gray.100",
      _hover: { bg: "gray.200" },
      _focus: { bg: "white", boxShadow: "outline" },
    },
    icon: {
      color: "gray.500",
      pointerEvents: "none",
    },
    results: {
      position: "absolute",
      top: "100%",
      left: 0,
      right: 0,
      mt: 2,
      boxShadow: "lg",
      borderRadius: "md",
      overflow: "hidden",
      zIndex: 10,
      maxH: "400px",
      overflowY: "auto",
    },
    resultItem: {
      p: 3,
      _hover: { bg: 'gray.100' },
      transition: "background-color 0.2s",
    },
    resultImage: {
      boxSize: "50px",
      objectFit: "cover",
      mr: 3,
      borderRadius: "md",
    },
    resultContent: {
      display: "flex",
      flexDirection: "column",
    },
    resultTitle: {
      fontWeight: "bold",
      fontSize: "sm",
      noOfLines: 1,
    },
    resultAuthor: {
      fontSize: "xs",
      color: "gray.600",
      noOfLines: 1,
    },
    resultIsbn: {
      fontSize: "xs",
      color: "gray.500",
    },
    resultPrice: {
      fontSize: "sm",
      fontWeight: "bold",
      color: "green.500",
    },
    noResults: {
      p: 3,
      textAlign: "center",
      color: "gray.500",
    },
  };